import { Switch, Route } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Error from "../Pages/Components/Error/Error404";

export const LoginRoute = (
  <Switch>
    <Route path="/" component={Login} />
    <Route render={() => <Error />} />
  </Switch>
);
