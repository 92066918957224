import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ZOptionMaster from './ZOptionMaster';
import MLFBMaster from './MLFBMaster';
import ThirteenThDigitMotorAuxSwitchMaster from './ThirteenThDigitMotorAuxSwitchMaster';
import FourteenThDigitClosingCoilRR from './FourteenThDigitClosingCoilRR';
import NineThDigitETUMaster from './NineThDigitETUMaster';
import Fifteen2ndAuxReleaseMaster from './Fifteen2ndAuxReleaseMaster';
import Sixteen1stAuxReleaseMaster from './Sixteen1stAuxReleaseMaster';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import SampleTemplate from './MLFB Breakup Sheet Template.xlsx';
import { useForm } from "react-hook-form";
import { validateTheUserBasedOnApi, } from '../../Redux/API/api_Login';
import { baseApiURL, appId } from "../../Utilities/utility";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from "react-redux";
import Error404Comp from "../Components/Error/Error404Comp";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
        (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //backgroundColor: "#001D3D",
        /*backgroundColor: "#001035",*/
        backgroundColor: "white",
        border: `none`,
    },
}));

const theme = createMuiTheme({
    overrides: {
        MuiTabs: {
            root: {
                /* backgroundColor: "#002949"*/
                backgroundColor: "white"
            }
        },
        MuiTab: {
            wrapper: {
                /*color: "#00CCCC"*/
                color: "black"
            }
        },
        MuiSvgIcon: {
            root: {
                color: "#00CCCC"
            }
        },
        PrivateTabIndicator: {
            root: {
                backgroundColor: "#00FFB9 !important"
            }
        }
    }
});

export default function MasterData(props) {
    //const auth = useAuth();
    //const dispatch = useDispatch();
    const token = useSelector((state) => state.saveAzureTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
        (state) => state.saveBearerTokenReducer.bearerToken
    );
    const [roleID, setRoleId] = useState(props.userDetails.roleId);
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const classes = useStyles();
    const [validationSValueMsg, setvalidationSValueMsg] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileValue, setfileValue] = useState('');
    const [open, setopen] = useState('');
    const [openAlert, setopenAlert] = useState('');
    const [openClassName, setopenClassName] = useState('');
    const [openType, setopenType] = useState('');
    const [value, setValue] = React.useState(0);
    const [openLoader, setopenLoader] = useState(false);
    const [tableData, setTableData] = useState([]);//(props.MLFBData);
    const [zoptionTableData, setZoptionTableData] = useState([]);
    const [thirteenDigitTableData, setThirteenDigitTableData] = useState([]);
    const [fourteenDigitTableData, setfourteenDigitTableData] = useState([]);
    const [nineDigitTableData, setNineDigitTableData] = useState([]);
    const [fifteenDigitTableData, setFifteenDigitTableData] = useState([]);
    const [sixteenDigitTableData, setSixteenDigitTableData] = useState([]);
    const [alerterror, setalerterror] = useState("Alert");
    const [alerterrorclass, setalerterrorclass] = useState("bg-red clr-white");
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openDesc, setOpenDesc] = React.useState("");
    //const [openAlert, setOpenAlert] = React.useState(false);
    const [openAlertDesc, setOpenAlertDesc] = React.useState("");
    const [userId, setUserId] = useState(props.userDetails.userId);
    function handleAlertClose() {
        setopen(false);
    }
    const handleClose = () => {
        setOpenSuccess(false);
    };
    const handlErrorClose = () => {
        setOpenError(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        callMLFBList(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        callZoptionList(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        callThirteenThDigit(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        callFourteenThDigit(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        callNineThDigit(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        callFifteenThDigit(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
        callSixteenThDigit(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId);
    }, []);
    function submitDatacall(e) {
        setvalidationSValueMsg([]);
        submitData();
        submitValueDetails();
    }
    function submitData() {
        setopenLoader(true);
    }
    function submitValueDetails() {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append('AppId', "3");
        formData.append('UserId', userId);
        bulkUploadPOList(
            token,
            bearerToken,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            userDetails.roleName,
            uId,
            formData);
    }
    const onSubmit = (data) => {
        console.log("Data", data);
    };
    const { register, handleSubmit } = useForm();

    function bulkUploadPOList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,
        formData) {
        setopenLoader(true);
        const options = {
            method: "post",
            //mode: "cors",
            headers: {
                //Accept: "application/json, text/plain, */*",
                //"Content-Type": "application/json",
                //"Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                //"Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: formData,
        };
        let uploadResponse = {
            response: false,
            responseMsg: "",
        };
        //fetch(baseApiURL + "MlfbFileUpload/MlfbBulkUpload?userid=" + userId + "&appid=" + appId, options)

        fetch(baseApiURL + "MlfbFileUpload/MlfbDumpUpload", options)
            .then((response) => {
                if (response.ok) {
                    setopenLoader(false);
                    response.json().then(json => {
                        // alert("File Uploaded Successfully");
                        if (json.result.response != false) {
                            if (json.result == "Enter Valid Template") {
                                showMsg(true, "Please upload valid template", alerterrorclass, alerterror);
                            }
                            else {
                                setOpenSuccess(true);
                                setOpenDesc(json.result[0] + ", " + json.result[1] + ", " + json.result[2] + ", " +
                                    json.result[4] + ", " + json.result[5] + ", " + json.result[6]);
                            }

                            //setOpenDesc("File Uploaded Successfully");
                            callMLFBList(
                                token,
                                bearerToken,
                                userDetails.userEmail,
                                userDetails.gid,
                                userDetails.roleId,
                                userDetails.roleName,
                                uId);
                            callZoptionList();
                            callThirteenThDigit(
                                token,
                                bearerToken,
                                userDetails.userEmail,
                                userDetails.gid,
                                userDetails.roleId,
                                userDetails.roleName,
                                uId);
                            callFourteenThDigit();
                            callNineThDigit(
                                token,
                                bearerToken,
                                userDetails.userEmail,
                                userDetails.gid,
                                userDetails.roleId,
                                userDetails.roleName,
                                uId);
                            callFifteenThDigit(token,
                                bearerToken,
                                userDetails.userEmail,
                                userDetails.gid,
                                userDetails.roleId,
                                userDetails.roleName,
                                uId);
                            callSixteenThDigit(
                                token,
                                bearerToken,
                                userDetails.userEmail,
                                userDetails.gid,
                                userDetails.roleId,
                                userDetails.roleName,
                                uId);
                        }
                        else {
                            setOpenError(true);
                            setOpenDesc(json.result.responseMsg);
                            setopenLoader(false);
                        }
                    })
                }
                else {
                    uploadResponse.response = false;
                    //alert("File Upload Failed");
                    setOpenSuccess(true);
                    setOpenDesc("File Upload Failed.");
                    setopenLoader(false);
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
                uploadResponse.response = false;
                //alert("File Upload Failed");
                setOpenError(true);
                setOpenDesc("File Upload Failed.");
                setopenLoader(false);
            });
        reset();
    }
    function showMsg(popen, popenAlert, popenClassName, popenType) {
        setopen(popen);
        setopenAlert(popenAlert);
        setopenClassName(popenClassName);
        setopenType(popenType);
    }
    function filePathset_Value(e) {
        e.stopPropagation();
        e.preventDefault();
        var valuetemplatefile = e.target.files[0];
        var elementSAP = document.getElementById("BulkUploadFile");
        var element1SAP = elementSAP.parentElement.parentElement;
        setSelectedFile(e.target.files[0]);
        element1SAP.classList.remove("udi-error");
        if (valuetemplatefile !== undefined) {
            var extension = valuetemplatefile.name.split('.')[valuetemplatefile.name.split('.').length - 1];
            if (extension.toString().toUpperCase() === 'XLSX') {
                setfileValue(valuetemplatefile);
            }
            else {
                setfileValue(null);
                document.getElementById('BulkUploadFile').value = null;
                showMsg(true, "Upload Excel file only.", alerterrorclass, alerterror);
            }
        }
    }
    const reset = () => {

        setfileValue("");
        document.getElementById('BulkUploadFile').value = null;
    }
    async function callMLFBList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                //Authorization: "Bearer " + props.Token,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMstBaseMlfbs", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setTableData(responseJson.result);
        }
    }

    async function callZoptionList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMstZoption", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setZoptionTableData(responseJson.result);
        }
    }

    async function callThirteenThDigit(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMst13thDigitMotorAuxSwitch", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setThirteenDigitTableData(responseJson.result);
        }
    }

    async function callFourteenThDigit(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                //Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMst14thDigitClosingCoilRr", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setfourteenDigitTableData(responseJson.result);
        }
    }

    async function callNineThDigit(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                //Authorization: "Bearer " + props.Token,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMst9thdigitETU", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setNineDigitTableData(responseJson.result);
        }
    }

    async function callFifteenThDigit(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMstFifteen2ndAuxRelease", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setFifteenDigitTableData(responseJson.result);
        }
    }

    async function callSixteenThDigit(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid) {
        const serviceAPI = {
            appId: appId
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                Authorization: "Bearer " + bearerToken,
                UserEmail: email,
                userId: uid,
                RoleName: roleName,
                Gid: gid,
                Roleid: roleID,
                AzureToken: siteToken,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(serviceAPI),
        };

        const response = await fetch(
            baseApiURL + "Master/GetMstSixteen1stAuxRelease", options
        );
        if (response.status == 401) {
            validateTheUserBasedOnApi(props.dispatch, props.auth);
        }
        else {
            const responseJson = await response.json();
            //console.log(responseJson.result);
            setSixteenDigitTableData(responseJson.result);
        }
    }

    return (
        roleID == 1 ?
            <div className={classes.root}>
                <div className="bulk-upload-block padding5px">
                    <div className='bulk-upload-sample-template border' style={{ backgroundColor: "white", marginLeft: "-11px", marginRight: "-9px" }}>
                        <form className="bulk-upload-form uploadPadding" onSubmit={handleSubmit(onSubmit)} >
                            <TextField
                                id="BulkUploadFile"
                                label="Bulk Upload"
                                shrink={true}
                                variant="filled"
                                className="bulk-upload"
                                type="file"
                                name="bulkUpload"
                                key={inputFileValue}
                                onChange={(e) => filePathset_Value(e)}
                            />
                            <Button className="pt-button--secondary bulk-upload-submit-btn">
                                <a
                                    href={SampleTemplate}
                                    download="MasterUpload_Template.xlsx"
                                >
                                    Template </a>
                            </Button>

                            <Button
                                className="pt-button--secondary bulk-upload-submit-btn"
                                type="submit"
                                disabled={selectedFile ? false : true}
                                style={{ marginLeft: '15px' }}
                                onClick={(e) => submitDatacall(e)}
                            >
                                Upload
                            </Button>
                            <Button
                                className="pt-button--secondary bulk-upload-submit-btn"
                                //type="submit"
                                disabled={selectedFile ? false : true}
                                style={{ marginLeft: '15px' }}
                                onClick={reset}
                            >
                                Clear
                            </Button>
                        </form>


                    </div>
                </div>
                <ThemeProvider theme={theme}>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example">
                            <Tab label="Base MLFB" {...a11yProps(0)} />
                            <Tab label="9th Digit ETU" {...a11yProps(1)} />
                            <Tab label="13th Digit Motor Aux Switch" {...a11yProps(2)} />
                            <Tab label="14th Digit Closing Coil RR" {...a11yProps(3)} />
                            <Tab label="15th 2nd Aux Release " {...a11yProps(5)} />
                            <Tab label="16th 1st Aux Release" {...a11yProps(6)} />
                            <Tab label="Z Options" {...a11yProps(7)} />


                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <MLFBMaster MLFBMasterList={tableData} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <NineThDigitETUMaster NineDigitList={nineDigitTableData} />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <ThirteenThDigitMotorAuxSwitchMaster ThirteenDigitList={thirteenDigitTableData} />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <FourteenThDigitClosingCoilRR FourteenDigitList={fourteenDigitTableData} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Fifteen2ndAuxReleaseMaster FifteenDigitList={fifteenDigitTableData} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <Sixteen1stAuxReleaseMaster SixteenDigitList={sixteenDigitTableData} />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <ZOptionMaster ZoptionList={zoptionTableData} />
                    </TabPanel>
                </ThemeProvider>


                <Dialog
                    open={openSuccess}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                >
                    <DialogTitle className={"bg-light-green clr-white"} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Success
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {openDesc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openSuccess}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                >
                    <DialogTitle className={"bg-light-green clr-white"} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Success
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {openDesc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openError}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                >
                    <DialogTitle className={"bg-light-green clr-white"} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Success
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {openDesc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openError}
                    onClose={handlErrorClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                >
                    <DialogTitle className={"bg-red clr-white"} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Error
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {openDesc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handlErrorClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess">
                    <DialogTitle className={openClassName} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {openType}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="clr-black">
                            {openAlert}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleAlertClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openLoader}
                    //onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                    PaperProps={{
                        style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            borderColor: "transparent"
                        },
                    }}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>

            </div>
            : <Error404Comp />
    );
}
