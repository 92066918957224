import { authUserAction, 
  storeUserDetailsAction, 
  saveAccessTokenAction, 
  errorMessageAction,
  saveUIDAction,
  saveBearerTokenAction} from '../Actions/actions';
import { baseApiURL, appId } from "../../Utilities/utility";
let gID, generatedToken = '';

export function authenticateUserBasedOnValidToken(dispatch, newToken, gID, auth) {
    //dispatch(errorMessageAction(""));
    const systemUser = {
        gid: gID,
        appId: appId
    };
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + newToken,
            "Access-Control-Allow-Origin": `${window.location.origin}`,
        },
        body: JSON.stringify(systemUser),
    };
    return fetch(baseApiURL + "Login/AuthenticateGId", options)
        .then((response) => response.json())
        .then((data) => {
            if (data.result) {
                dispatch(storeUserDetailsAction(data.result));
               return true;
            } else {
                return false;
            }
        })
        .catch((error) => {
            validateTheUserBasedOnApi(dispatch, auth);
            authenticateUserBasedOnValidToken(dispatch, newToken, gID, auth);
        });
    //try {
    //    const appURL = baseApiURL + "Login/";
    //    const response = fetch(appURL, options);
    //    const responseJson = response.json();
    //    dispatch(storeUserDetailsAction(responseJson.result));
    //}
    //catch (error) {
    //    validateTheUserBasedOnApi(dispatch, auth);
    //    authenticateUserBasedOnValidToken(dispatch, newToken, gID, auth);
    //}
}

export function validateTheUserBasedOnApi(dispatch,token, AppID) {
  console.log(token);
  dispatch(errorMessageAction(""));
  //const arr = auth.user.profile.sub.split("|");
  // let gid = arr && arr.length ? arr[1] : "";
  // const systemUser = {
  //   userEmailId: gid,
  //   userGID: gid,
  //   appId: AppID,
  //   token: token,
  // };
  var formdataPart1 = new FormData();
  formdataPart1.append("Token", token);
  formdataPart1.append("AppId", AppID);
  const options = {
    method: "post",
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formdataPart1,
  };
  return fetch(baseApiURL + "Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((responseTokenUserD) => {
      console.log(responseTokenUserD)
      if (responseTokenUserD.result.tokenString !== undefined) {
        // if (auth !== undefined && auth.user) {
        //   const arr = auth.user.profile.sub.split("|");
        //   gID = arr && arr.length ? arr[1] : "";
        //   dispatch(authUserAction(gID));
        // }
        //generatedToken = responseTokenUserD.token;
        dispatch(saveUIDAction(responseTokenUserD.result.uid));
        dispatch(saveBearerTokenAction(responseTokenUserD.result.tokenString));
        dispatch(storeUserDetailsAction(responseTokenUserD.result.response));
        return true;
      } else {
        dispatch(storeUserDetailsAction(""));
        dispatch(errorMessageAction("Login Failed, User is not found in system.")
        );
      }
    })
    .catch((error) => dispatch(errorMessageAction("Login Failed.")));
  //const appURL = baseApiURL + "Login/ValidateRequest";
  //const response =  fetch(appURL, options);
  //const responseJson = response.json();
  //if (responseJson.token !== undefined) {
  //    if (auth !== undefined && auth.user) {
  //        const arr = auth.user.profile.sub.split('|');
  //        gID = arr && arr.length ? arr[1] : "";
  //        //console.log("gid dispatch", gID);
  //        dispatch(authUserAction(gID));
  //    }
  //    generatedToken = responseJson.token;
  //    //console.log('new token for ext', generatedToken);
  //    dispatch(saveAccessTokenAction(generatedToken));
  //}
  //else {
  //    console.log(responseJson.result);
  //    console.log(AppID);
  //    //dispatch(storeUserDetailsAction(responseJson.result));
  //    dispatch(errorMessageAction("Login Failed, User is not found in system."));
  //}
}

// We are getting ONLY token from this api
export function validateTheUserBasedOnApi_old(dispatch, auth) {
    const arr = auth.user.profile.sub.split("|");
    let gid = arr && arr.length ? arr[1] : "";
    const systemUser = {
        userEmailId: gid,
        userGID: gid,
    };
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": `${window.location.origin}`,
        },
        body: JSON.stringify(systemUser),
    };
    const appURL = baseApiURL + "Login/ValidateRequest";

    fetch(appURL, options)
    .then((res) => res.json())
    .then((res_token) => {
      if (auth !== undefined && auth.user) {
        const arr = auth.user.profile.sub.split('|');
        // setGid(arr && arr.length ? arr[1] : "");
        // gID = "Z004BVDP";
        gID = arr && arr.length ? arr[1] : "";
        console.log("gid dispatch", gID);
        dispatch(authUserAction(gID));
      }
      // token = res_token.token;
      // console.log("Res Token:", res_token.token);
      generatedToken = res_token.token;
      console.log('new token for ext', generatedToken);
      dispatch(saveAccessTokenAction(generatedToken));
    })
    .catch((error) =>
      // console.log("Error in Token at Validation level:", error)
      dispatch(errorMessageAction("Invalid Email or Password"))
        //dispatch(storeUserDetailsAction())
    );
}

export async function authenticateExternalUser(dispatch, newToken, userData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(userData),
  };
    const appURL = baseApiURL + "Login/AuthenticateExternalUser";
    const response = await fetch(appURL, options);
  const responseJson = await response.json();
  const responseResult = responseJson.result;
  console.log("extt user auth", responseResult);
  if (responseResult.gid !== null && responseResult.firstName !== null) {
    dispatch(storeUserDetailsAction(responseJson.result));
    dispatch(errorMessageAction(''));
  }
  else {
    dispatch(errorMessageAction("Invalid Email or GID"));
  }
}


export async function getUserManagementReportFile(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid) {
   const options = {
      method: "get",
      headers: {
        Authorization: "Bearer " + bearerToken,
        UserEmail: email,
        userId: uid,
        RoleName: roleName,
        Gid: gid,
        Roleid: roleID,
        AzureToken: siteToken,
      },
    };
    return fetch(
      baseApiURL + "CreateOffer/ExportUserCourseReport?UserId=" + gid , options,
      
    )
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
   
}






